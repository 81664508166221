@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body::-webkit-scrollbar{
  width: 10px;
  background-color: #f1f1f6;
}

body::-webkit-scrollbar-thumb{
  background-color: #4ecb84;
  border-radius: 10px;
  border: 2px solid #f1f1f6;
}

button {
  user-select: none;
}

.app{
  position: relative;
  min-height: 100vh;
  height: max-content;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  color: #232323;
  background-color: #f1f1f6;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 115px;
  /*transition-duration: 0.5s;*/
}

nav {
  background-color: #fff;
  position: fixed;
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition-duration: 500ms;
  inset: 10px;
  z-index: 10;
}

.menuBtn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 18px;
  border-bottom: 1px solid rgba(0,0,0,0.25);
  height: 50px;
}

.menuBtn:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #000;
  transform: translateY(-7px);
  transition-duration: 500ms;
}

.menuBtn:after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #000;
  box-shadow: 0 -7px 0 #000;
  transform: translateY(7px);
  transition-duration: 500ms;
}

nav.active {
  width: 210px;
  border-right: 1px solid rgba(0,0,0,0.25);
}

nav.active .menuBtn:before {
  transform: rotate(45deg);
}

nav.active .menuBtn:after {
  transform: rotate(-45deg);
  box-shadow: unset;
}

nav ul{
  margin-top: 65px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

nav ul li{
  position: relative;
  width: 100%;
  height: 60px;
  border-radius: 12px;
  border: 6px solid transparent;
  transition-duration: 500ms;
}

nav ul li.active{
  transform: translateX(25px);
  background-color: #f1f1f6;
}

nav.active ul li.active{
  transform: translateX(25px);
  background-color: #4ecb84;
}

nav ul li:before{
  content: '';
  position: absolute;
  background-color: #fff;
  height: 10px;
  width: 10px;
  top: -16px;
  left: 19px;
  border-bottom-right-radius: 10px;
  box-shadow: 6px 4px 0 4px #f1f1f6;
  opacity: 0;
  transition-duration: 500ms;
  transform: scale(0);
}

nav ul li:after{
  content: '';
  position: absolute;
  background-color: #fff;
  height: 10px;
  width: 10px;
  bottom: -16px;
  left: 19px;
  border-top-right-radius: 10px;
  box-shadow: 6px -4px 0 4px #f1f1f6;
  opacity: 0;
  transition-duration: 500ms;
  transform: scale(0);
}

nav ul li.active:before, li.active:after{
  opacity: 1;
  transform: scale(1);
}

nav.active ul li.active:before, nav.active li.active:after{
  box-shadow: unset;
  transform: scale(0);
  background-color: unset;
}

nav.active ul li:before, nav.active ul li:after{
  left: 192px;
}

nav ul a li, nav ul p li {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 10;
}

nav ul a li .icon, nav ul p li .icon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #4a525f;
  font-size: 1.5em;
  transition-duration: 0.5s;
}

nav ul a li.active .icon, nav ul p li.active .icon{
  background-color: #4ecb84;
  color: #fff;
}

nav ul a li.active .icon:before, nav ul p li.active .icon:before{
  content: '';
  position: absolute;
  left: -42px;
  background-color: #4ecb84;
  border-radius: 50%;
  border: 5px solid #f1f1f6;
  height: 24px;
  width: 24px;
  transition-duration: 0.5s;
}

nav ul a li .text, nav ul p li .text{
  position: relative;
  padding: 0 15px;
  color: #f1f1f6;
  display: flex;
  align-items: center;
  height: 60px;
  visibility: hidden;
  opacity: 0;
  transition-duration: 500ms;
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: 500;
}

nav.active ul a li .text, nav.active ul p li .text{
  visibility: visible;
  opacity: 1;
  color: #232323;
}

nav.active ul a li.active .text, nav.active ul p li.active .text{
  visibility: visible;
  opacity: 1;
  color: #ffffff;
}

.settings {
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.profile {
  position: absolute;
  bottom: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

nav ul li .text{
  position: relative;
  padding: 0 15px;
  color: #f1f1f6;
  display: flex;
  align-items: center;
  height: 30px;
  visibility: hidden;
  opacity: 0;
  transition-duration: 500ms;
  white-space: nowrap;
  font-size: 1.2em;
}

nav.active ul li .text{
  visibility: visible;
  opacity: 1;
  color: #232323;
}

.LD-mode {
  display: flex;
}

.switch {
  border: 1px solid #4ecb84;
  background-color: #ffffff;
  border-radius: 15px;
  width: 60px;
  height: 31px;
  display: flex;
  align-items: center;
  padding: 5px;
  position: relative;
  transition-duration: 0.5s;
}

.switch div{
  border-radius: 50%;
  height: 21px;
  width: 21px;
  background-color: #4ecb84;
  position: absolute;
  left: 5px;
  transition-duration: 0.5s;
}

.switch.on {
  background-color: #4ecb84;
}

.switch.on div{
  border-radius: 50%;
  height: 21px;
  width: 21px;
  background-color: #ffffff;
  position: absolute;
  left: 34px;
  transition-duration: 0.5s;
}

section.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  color: #232323;
  background-color: #f1f1f6;
}

.login .box{
  position: relative;
  width: 400px;
  height: 500px;
  border-radius: 40px;
  overflow: hidden;
}

.login .box:before{
  content: '';
  position: absolute;
  width: 400px;
  height: 500px;
  top: -50%;
  left: -50%;
  background: linear-gradient(0deg,transparent,
  #4ecb84,#4ecb84);
  transform-origin: bottom right;
  animation: animate 5s linear infinite;
}

.login .box:after{
  content: '';
  position: absolute;
  width: 400px;
  height: 500px;
  bottom: -50%;
  right: -50%;
  background: linear-gradient(0deg,#4ecb84,
  #4ecb84,transparent);
  transform-origin: top left;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.login .form{
  position: absolute;
  background-color: #fff;
  border-radius: 40px;
  /*box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);*/
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 18px;
  inset: 2px;
  z-index: 1;
}

.login .form div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  flex-direction: column;
}

.login .form svg {
  font-size: 56px;
  color: #4ecb84;
}

.login .form div label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  position: relative;
}

.login .form div label span{
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  pointer-events: none;
  transition-duration: 500ms;
}

.login .form div label input{
  font-size: 1em;
  padding: 10px 10px ;
  width: 100%;
  z-index: 2;
  background-color: transparent;
  color: #fff;
}

.login .form div label input::selection{
  background: #858585;
}

.login .form div label input:valid ~ span,
.login .form div label input:focus ~ span{
  color: #4ecb84;
  transform: translateY(-30px);
  font-size: 0.85em;
}

.login .form div label i{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: #4ecb84;
  width: 100%;
  border-radius: 4px;
  transition-duration: 500ms;
  pointer-events: none;
}

.login .form div label input:valid ~ i,
.login .form div label input:focus ~ i{
  height: 40px;
}

.login .form div label p{
  position: absolute;
  left: 0;
  bottom: -25px;
  font-size: 0.7em;
  padding: 10px 10px 10px;
  pointer-events: none;
  color: #ff0000;
  font-weight: bold;
}

.login .form button {
  background-color: #4ecb84;
  color: white;
  font-size: inherit;
  border-radius: 10px;
  padding: 10px 30px;
  transition-duration: 500ms;
}

.login .form button:active {
  opacity: 0.8;
}

section {
  height: max-content;
  padding: 15px 40px 15px 0;
}

section .head {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section .head .block {
  display: flex;
  align-items: center;
}

section .head .block .icon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 50px;
  background-color: #4ecb84;
  border-radius: 10px;
  color: #fff;
  font-size: 1.2em;
  transition-duration: 0.5s;
  margin-right: 10px;
}

section .head .block .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 45px;
  align-items: flex-start;
}

section .head .text h1 {
  font-size: 24px;
  font-weight: 700;
}

section .head .text a {
  color: #0177fd;
}

section .head .notification-block{
  position: relative;
}

section .head .notification-block .main-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #0177fd;
  font-size: 1.2em;
  transition-duration: 0.5s;
  position: relative;
  cursor: pointer;
}

section .head .notification-block .main-icon .counter {
  font-size: 10px;
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #ee5037;
  color: #ffffff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

section .head .notification-block .notification-list {
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 35px;
  border-radius: 8px;
  width: 0;
  background-color: #ffffff;
  overflow-y: auto;
  box-shadow: 0 5px 5px rgba(96, 96, 96, 0.3);
  transition-duration: 200ms;
  z-index: 100000;
  /*padding-bottom: 46px;*/
}

section .head .notification-block .notification-list::-webkit-scrollbar{
  width: 10px;
  background-color: transparent;
}

section .head .notification-block .notification-list::-webkit-scrollbar-thumb{
  background-color: #0177fd;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

section .head .notification-block .notification-list.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 35px;
  max-height: 672px;
  overflow-y: auto;
  border-radius: 8px;
  width: 600px;
  background-color: #ffffff;
  overflow-y: auto;
  box-shadow: 0 5px 5px rgba(96, 96, 96, 0.3);
}

section .head .notification-block .notification-list .notification {
  display: flex;
  /*padding: 20px 20px;*/
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

section .head .notification-block .notification-list .notification a span,
section .head .notification-block .notification-list .notification .custom-notification span{
  position: absolute;
  bottom: 6px;
  right: 50px;
  font-size: 12px;
}

section .head .notification-block .notification-list .notification:hover {
  background-color: #f3f3f3;
}

section .head .notification-block .notification-list a,
section .head .notification-block .notification-list .custom-notification{
  color: #232323;
  padding: 20px;
  width: 550px;
  position: relative;
  cursor: pointer;
}

section .head .notification-block .notification-list a .text,
section .head .notification-block .notification-list .custom-notification .text{
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

section .head .notification-block .notification-list .notification .status {
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 56px;
}

section .head .notification-block .notification-list .notification .delete {
  display: none;
  color: #ee5037;
}

section .head .notification-block .notification-list .notification:hover .delete {
  display: flex;
}

section .head .notification-block .notification-list .notification:hover .uncheck {
  display: none;
}

section .head .notification-block .notification-list .notification .uncheck {
  border-radius: 50%;
  background-color: #0177fd;
  height: 10px;
  width: 10px;
}

.preload {
  min-height: calc(100vh - 163px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 115px);
}

.modal-table .preload {
  width: 100%;
  height: 100%;
  min-height: unset;
}

.preload .preload-contain {
  position: absolute;
  /*left: 40%;*/
  /*right: 40%;*/
  /*bottom: 45%;*/
  /*top: 35%;*/
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 235px;
  overflow: hidden;
  transform: rotate(45deg) scale(1.3);

}

.preload .preload-contain:before{
  content: '';
  position: absolute;
  width: 180px;
  height: 235px;
  top: -50%;
  left: -50%;
  background: linear-gradient(0deg,transparent,
  #4ecb84,#4ecb84);
  transform-origin: bottom right;
  animation: animate 2s linear infinite;
}

.preload .preload-contain:after{
  content: '';
  position: absolute;
  width: 180px;
  height: 235px;
  bottom: -50%;
  right: -50%;
  background: linear-gradient(0deg,#4ecb84,
  #4ecb84,transparent);
  transform-origin: top left;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.preload .preload-contain .preload-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f6;
  width: 175px;
  height: 230px;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
}

.preload .preload-contain .preload-icon img {
  position: absolute;
  z-index: 20;
  transform: rotate(-75deg);
  pointer-events: none;
}

.table {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px;
  min-height: calc(100vh - 105px);
  height: max-content;
  padding: 0 0 70px 0;
}

.table .control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
}

.table .control div {
  display: flex;
}

.table .control #search {
  padding: 7px 10px;
  font-size: 16px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  align-items: center;
  margin-right: 10px;
  transition-duration: 200ms;
}

.table .control #search input {
  font-size: 16px;
  margin-right: 5px;
}

.table .control .create {
  display: flex;
  align-items: center;
  background-color: #0177fd;
  font-size: 16px;
  padding: 7px 35px;
  border-radius: 5px;
  color: #ffffff;
  margin-right: 10px;
  transition-duration: 200ms;
}

.table .control .create:hover {
  background-color: #0067e3;
}

.table .control .create:active {
  background-color: #005cc7;
}

.table .control .create span {
  margin-left: 5px;
}

.table .control .reload {
  border: 1px solid #e5e5e5;
  width: 38px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 20px;
  transition-duration: 200ms;
}

.table .control .reload:hover {
  background-color: #0177fd;
  color: #ffffff;
}

.table .control .reload:active {
  background-color: #0067e3;
  color: #ffffff;
}

.table .control #search:focus-within, .table .control #search.valid{
  border: 1px solid #0177fd;
  color: #0177fd;
}

table .row-type-1 {
  background-color: #ffffff;
}

table .row-type-2 {
  background-color: #f3f3f3;
}

.table table {
  width: 100%;
  border-collapse: collapse;
  color: #4a525f;
}

.users-page .table table .col1,
.modal-table .table.select-users .col1{
  width: 5%;
}.users-page .table table .col2,
.modal-table .table.select-users .col2 {
  width: 10%;
}.users-page .table table .col3,
.modal-table .table.select-users .col3 {
  width: 10%;
}.users-page .table table .col4,
.modal-table .table.select-users .col4 {
  width: 20%;
}.users-page .table table .col5,
.modal-table .table.select-users .col5 {
  width: 10%;
}.users-page .table table .col6,
.modal-table .table.select-users .col6 {
  width: 13%;
}.users-page .table table .col7,
.modal-table .table.select-users .col7 {
  width: 15%;
}.users-page .table table .col8,
.modal-table .table.select-users .col8 {
  width: 12%;
}.users-page .table table .col9,
.modal-table .table.select-users .col9 {
  width: 5%;
}

.tasks-page .table table .col1,
.notification-block .table.Tasks .col1,
.notification-block .table.Projects .col1{
  width: 5%;
}.tasks-page .table table .col2,
 .notification-block .table.Tasks .col2,
 .notification-block .table.Projects .col2{
  width: 5%;
}.tasks-page .table table .col3,
 .notification-block .table.Tasks .col3,
 .notification-block .table.Projects .col3{
  width: 20%;
}.tasks-page .table table .col4,
 .notification-block .table.Tasks .col4,
 .notification-block .table.Projects .col4{
  width: 15%;
}.tasks-page .table table .col5,
 .notification-block .table.Tasks .col5,
 .notification-block .table.Projects .col5{
  width: 10%;
}.tasks-page .table table .col6,
 .notification-block .table.Tasks .col6,
 .notification-block .table.Projects .col6{
  width: 10%;
}.tasks-page .table table .col7,
 .notification-block .table.Tasks .col7,
 .notification-block .table.Projects .col7{
  width: 10%;
}.tasks-page .table table .col8,
 .notification-block .table.Tasks .col8,
 .notification-block .table.Projects .col8{
  width: 10%;
}.tasks-page .table table .col9,
 .notification-block .table.Tasks .col9,
 .notification-block .table.Projects .col9{
  width: 10%;
}.tasks-page .table table .col10,
 .notification-block .table.Tasks .col10,
 .notification-block .table.Projects .col10{
  width: 5%;
}.notification-block .table.Tasks .col10.none,
 .notification-block .table.Projects .col10.none {
  padding: 0;
  width: 0;
}

.notification-block .table.notifications table .col1 {
  width: 5%;
}.notification-block .table.notifications table .col2 {
  width: 20%;
}.notification-block .table.notifications table .col3 {
  width: 10%;
}.notification-block .table.notifications table .col4 {
  width: 15%;
}.notification-block .table.notifications table .col5 {
  width: 15%;
}.notification-block .table.notifications table .col6 {
  width: 15%;
}.notification-block .table.notifications table .col7 {
  width: 15%;
}.notification-block .table.notifications table .col8 {
  width: 5%;
}

.events-page .table table .col1 {
  width: 5%;
}.events-page .table table .col2 {
  width: 20%;
}.events-page .table table .col3 {
  width: 30%;
}.events-page .table table .col4 {
  width: 25%;
}.events-page .table table .col5 {
  width: 20%;
  display: table-cell;
}

.events-page .table.all-events table .col1 {
  width: 20%;
}.events-page .table.all-events table .col2 {
  width: 15%;
}.events-page .table.all-events table .col3 {
  width: 20%;
}.events-page .table.all-events table .col4 {
  width: 30%;
}.events-page .table.all-events table .col5 {
  width: 15%;
}

table .col1 {
  position: relative;
}

table .col1 p {
  bottom: 33%;
  left: -25px;
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.table table td:last-child {
  display: flex;
}
.table table td a {
  color: #0177fd;
}

.table table td:last-child a {
  margin: 0 2px;
  color: #0177fd;
  cursor: pointer;
  transition-duration: 200ms;
  user-select: none;
}

.table table td:last-child span {
  margin: 0 2px;
  color: #0177fd;
  cursor: pointer;
  transition-duration: 200ms;
  padding: unset !important;
  user-select: none;
}

.table table td:last-child a:hover {
  color: #0067e3;
}

.table table td:last-child a:active {
  color: #005cc7;
}

.table table th{
  padding: 10px 0;
  cursor: pointer;
  user-select: none;
}

.table table td {
  text-align: center;
  padding: 14px 10px;
}

.table table th div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
}

.table table th div svg {
  margin: -4px 0;
}

.table table th .table-head-arrows {
  display: flex;
  flex-direction: column;
}

td span {
  border-radius: 3px;
  padding: 2px 15px;
  white-space: nowrap;
}

.bg-green {
  /*background-color: #4ecb84;*/
  background-color: #4ecb84;
  color: #ffffff;
}

.bg-red {
  background-color: #ee5037;
  color: #ffffff;
}

.bg-black {
  background-color: #232323;
  color: #ffffff;
}

.bg-semi-red {
  background-color: rgba(238, 80, 55, 0.7);
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  color: #232323;
}

.bg-semi-blue {
  background-color: rgba(1, 119, 253, 0.7);
  color: #ffffff;
}

.bg-blue {
  background-color: #0177fd;
  color: #ffffff;
}

.bg-purple {
  background-color: #aa00ff;
  color: #ffffff;
}

.bg-yellow {
  background-color: #ffc300;
  color: #ffffff;
}

.bg-gradient {
  background: linear-gradient(to right, #ee5037, #aa00ff);
  color: #ffffff;
}

.pagination {
  user-select: none;
  position: absolute;
  bottom: 20px;
  right: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #484848;
}

.pagination .start, .pagination .back, .pagination .next, .pagination .end {

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 28px;
  padding: 0 5px;
  transition-duration: 200ms;
  cursor: pointer;
}

.pagination .back, .pagination .next {
  padding: 0 15px;
}

.pagination .start:hover, .pagination .back:hover, .pagination .next:hover, .pagination .end:hover {
  color: #0177fd;
}

.pagination .pages span {
  padding: 5px 12px;
  cursor: pointer;
}

.pagination .pages span.active {
  background-color: #0177fd;
  color: #ffffff;
}

.pagination .count-records {
  font-size: 16px;
  margin-right: 50px;
}

.table .control .switch-count-records {
  display: flex;
  align-items: center;
  user-select: none;
}

.custom-select.small {
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  border: 1px solid #e5e5e5;
  padding: 3px 10px;
  border-radius: 5px;
  transition-duration: 200ms;
  cursor: pointer;
  user-select: none;
}

.custom-select.small:hover {
  background-color: #f3f3f3;
}

.custom-select.small:active {
  background-color: #f1f1f6;
}

.custom-select .value-block .value {
  margin-right: 5px;
}

.custom-select.small .values-block {
  position: absolute;
  top: 25px;
  z-index: 5;
  background-color: #ffffff;
  /*height: 63px;*/
  width: 60px;
  height: 0;
  overflow: hidden;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  transition-duration: 500ms;
}

.custom-select.small .values-block span {
  width: 100%;
  text-align: center;
  padding: 3px;
  height: 21px;
  cursor: pointer;
  font-size: 0.9em;
}

.custom-select.small .values-block span:hover {
  background-color: #f1f1f6;
}

.custom-select.small.active .values-block {
  display: flex;
  height: max-content;
}

.custom-select.small .icon {
  transition-duration: 200ms;
}

.custom-select.small.active .icon {
  transform: rotate(180deg);
}

.table-contain {
  background-color: #ffffff;
  border-radius: 15px;
  position: relative;
}

.sub-nav {
  padding: 15px 20px 15px;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
}

.sub-nav.modal-sub-nav {
  width: 100%;
  cursor: pointer;
}

.view-page .sub-nav span {
  font-size: 16px;
}

.view-page .row {
  display: flex;
  justify-content: space-between;
}

.sub-nav a {
  display: flex;
}

.sub-nav span {
  color: #232323;
  font-size: 18px;
  width: max-content;
  padding: 5px 10px;
  transition-duration: 200ms;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
}

.sub-nav span.active {
  color: #4ecb84;
  border-bottom: 2px solid #4ecb84;
}

.modal-window, .confirm-window, .modal-table {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000001;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.29);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window.image {
  background-color: rgba(0, 0, 0, 0.2)
}

.modal-window .contain {
  max-width: 70%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
}

.modal-window .contain img {
  width: 100%;
}

.modal-window .form {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
}

.confirm-window .confirm-contain {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}

.confirm-window .confirm-contain h3 {
  font-size: 24px;
}

.modal-window .form::-webkit-scrollbar{
  width: 10px;
  background-color: transparent;
}

.modal-window .form::-webkit-scrollbar-thumb{
  background-color: #4ecb84;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.users-page .modal-window .form,
.users-view-page .modal-window .form{
  width: 550px;
  height: 754px;
}

.tasks-page .modal-window .form,
.tasks-view-page .modal-window .form,
.notification-block .modal-window .form{
  width: 550px;
  height: max-content;
  padding-bottom: 20px;
}

.modal-window .form h2 {
  text-align: center;
  font-size: 24px;
  padding: 30px 0 30px;
}

.modal-window .form .fields {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 18px;
  padding: 0 30px;
  justify-content: flex-start;
}

.modal-window .form .fields label{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.tasks-page .modal-window .form .fields label,
.tasks-view-page .modal-window .form .fields label,
.notification-block .modal-window .form .fields label{
  flex-direction: column;
}

.notification-block .modal-window .form .fields label {
  align-items: flex-start !important;
}

.users-page .modal-window .form .fields label,
.users-view-page .modal-window .form .fields label{
  align-items: center;
}

.users-page .modal-window .form .fields label span,
.users-view-page .modal-window .form .fields label span{
  text-align: center;
  width: 25%;
}

.users-page .modal-window .form .fields input,
.users-view-page .modal-window .form .fields input{
  width: 75%;
}

.tasks-page .modal-window .form .fields label span,
.tasks-view-page .modal-window .form .fields label span,
.notification-block .modal-window .form .fields label span{
  text-align: start;
  width: 100%;
}

.modal-window .form .fields input {
  border: 1px solid #e5e5e5;
  font-size: 18px;
  border-radius: 8px;
  padding: 4px 10px;
  margin: 10px 0 10px 20px;
}

.tasks-page .modal-window .form .fields input,
.tasks-view-page .modal-window .form .fields input,
.notification-block .modal-window .form .fields input{
  border: 1px solid #e5e5e5;
  font-size: 18px;
  border-radius: 8px;
  padding: 4px 10px;
  margin: 10px 0 20px;
}

.modal-window .form .fields input::placeholder {
  font-size: 16px;
}

.tasks-page .modal-window .form .fields input,
.tasks-view-page .modal-window .form .fields input,
.notification-block .modal-window .form .fields input{
  width: 100%;
}

.tasks-page .modal-window .form .fields textarea,
.tasks-view-page .modal-window .form .fields textarea,
.notification-block .modal-window .form .fields textarea{
  border: 1px solid #e5e5e5;
  font-size: 18px;
  margin: 10px 0 20px;
  border-radius: 8px;
  padding: 4px 10px;
  width: 100%;
}

.modal-window .form .buttons {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  height: 36px;
  margin: 30px 0 0;
}

.confirm-window .confirm-contain .buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 36px;
  margin: 30px 0 0;
}

.modal-window .form .create{
  background-color: #4ecb84;
  /*left: 130px;*/
  transform: translateX(-60px);
}

.confirm-window .confirm-contain .create {
  background-color: #4ecb84;
}

.modal-window .form .create:hover,
.confirm-window .confirm-contain .create:hover {
  background-color: #47b778;
}

.modal-window .form .create:active,
.confirm-window .confirm-contain .create:active {
  background-color: #3d9d67;
}

.modal-window .form .cancel{
  background-color: #ee5037;
  /*right: 130px;*/
  transform: translateX(60px);
}

.confirm-window .confirm-contain .cancel {
  background-color: #ee5037;
}

.modal-window .form .cancel:hover,
.confirm-window .confirm-contain .cancel:hover {
  background-color: #d24932;
}

.modal-window .form .cancel:active,
.confirm-window .confirm-contain .cancel:active {
  background-color: #b23d2a;
}

.modal-window .form .cancel, .modal-window .form .create{
  padding: 7px 20px;
  font-size: 16px;
  border-radius: 5px;
  position: absolute;
  color: #FFFFFF;
  transition-duration: 200ms;
  margin: unset;
}

.confirm-window .confirm-contain .create, .confirm-window .confirm-contain .cancel {
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 16px;
  padding: 7px 20px;
  margin: 0 20px;
}

.custom-select-type1 {
  border: 1px solid #e5e5e5;
  font-size: 18px;
  border-radius: 8px;
  padding: 4px 10px;
  margin: 10px 0 10px 20px;
  width: 75%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tasks-page .modal-window .custom-select-type1,
.tasks-view-page .modal-window .custom-select-type1,
.notification-block .modal-window .custom-select-type1{
  margin: 10px 0 20px;
  width: 100%;
}

.tasks-page .modal-window .custom-select-type1 .select-value span,
.tasks-view-page .modal-window .custom-select-type1 .select-value span,
.notification-block .modal-window .custom-select-type1 .select-value span{
  text-align: center !important;
}

.custom-select-type1 .select-value, .custom-select-type2 .select-value {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;
  cursor: pointer;
}

.custom-select-type1 .select-value span{
  width: 100% !important;
}

.custom-select-type1 .select-value svg {
  position: absolute;
  right: 10px;
  color: #4ecb84;
  transition-duration: 200ms;
}

.custom-select-type1 .values-list, .custom-select-type2 .values-list {
  display: none !important;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  overflow: hidden;
  z-index: 10;
}

.notification-block .custom-select-type1 .values-list {
  top: 36px;
}

.custom-select-type1 .values-list {
  overflow-y: auto;
  max-height: 252px;
}

.custom-select-type1 .values-list::-webkit-scrollbar{
  width: 10px;
  background-color: #f1f1f6;
}

.custom-select-type1 .values-list::-webkit-scrollbar-thumb{
  background-color: #4ecb84;
  border-radius: 10px;
  border: 2px solid #f1f1f6;
}


.custom-select-type1 .values-list span, .custom-select-type2 .values-list span {
  padding: 5px 0 !important;
  width: 100% !important;
  text-align: center !important;
  cursor: pointer !important;
}

.custom-select-type2 .values-list span {
  font-size: 18px;
  padding: 10px 0 !important;
}

.custom-select-type1 .values-list span:hover, .custom-select-type2 .values-list span:hover {
  background-color: #f1f1f6;
}

.custom-select-type1.active .values-list, .custom-select-type2.active .values-list {
  display: flex !important;
}

.custom-select-type1.active svg {
  transform: rotate(180deg);
}

.custom-select-type2 {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #0177fd;
  font-size: 16px;
  padding: 7px 35px;
  border-radius: 5px;
  color: #ffffff;
  transition-duration: 200ms;
  cursor: pointer;
  height: 38px;
}

.custom-select-type2 svg {
  transition-duration: 200ms;
  margin-right: 10px;
}

.custom-select-type2:hover {
  background-color: #0067e3;
}

.custom-select-type2:active {
  background-color: #005cc7;
}

.custom-select-type2.active svg {
  transform: rotate(180deg);
}

.custom-select-type2 .values-list {
  top: 40px;
  color: #232323;
}

.select-bg {
  transition-duration: 200ms;
}

.select-bg.green {
  background-color: rgba(78, 203, 132, 0.4);
}.select-bg.purple {
  background-color: rgba(170, 0, 255, 0.4);
}.select-bg.red {
  background-color: rgba(238, 80, 55, 0.4);
}.select-bg.semi-red {
  background-color: rgba(238, 80, 55, 0.2);
}.select-bg.white {
  background-color: #ffffff;
}.select-bg.semi-blue {
  background-color: rgba(1, 119, 253, 0.2);
}.select-bg.blue {
  background-color: rgba(1, 119, 253, 0.4);
}

.select-bg.green:hover   {
  background-color: rgba(78, 203, 132, 0.5) !important;
}.select-bg.purple:hover {
   background-color: rgba(170, 0, 255, 0.5) !important;
}.custom-select-type1 .select-bg.red:hover {
  background-color: rgba(238, 80, 55, 0.5) !important;
}.custom-select-type1 .select-bg.semi-red:hover {
  background-color: rgba(238, 80, 55, 0.3) !important;
}.custom-select-type1 .select-bg.semi-blue:hover {
  background-color: rgba(1, 119, 253, 0.3) !important;
}.custom-select-type1 .select-bg.blue:hover {
  background-color: rgba(1, 119, 253, 0.5) !important;
}

.view-page {
  display: flex;
  justify-content: space-between;
}

.view-page .left-side {
  width: 30%;
  height: 100%;
}

.view-page .left-side .contain-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 40px 40px;
  height: 655px;
}

.users-view {
  display: flex;
  justify-content: space-between;
}

.users-view .left-side {
  width: 30%;
  height: 100%;
}

.users-view .left-side .contain-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px 40px 40px;
  height: 720px;
}

.tasks-view .left-side .contain-info {
  min-height: 725px;
  height: max-content;
}

.view-page .left-side .contain-info h3 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.view-page .contain-info .info-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.view-page .contain-info .info-block:last-child {
  margin-bottom: unset;
}

.view-page .contain-info .info-block span {
  font-size: 22px;
}

.view-page .contain-info .info-block span.span {
  font-size: 22px;
  padding: 5px 20px;
  border-radius: 4px;
  width: max-content;
}

.view-page .contain-info .info-block span.header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.view-page .left-side .contain-status {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px 40px;
  margin-top: 40px;
}

.view-page .left-side .contain-status .bottom {
  display: flex;
  align-items: center;
  padding: 20px 0 0;
}

.view-page .left-side .contain-status .bottom h3 {
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
}

.view-page .left-side .contain-status .buttons {
  display: flex;
  justify-content: space-between;
}

.view-page .left-side .contain-status button {
  font-size: 18px;
  padding: 5px 0;
  width: 100%;
  border-radius: 5px;
  transition-duration: 200ms;
  margin-bottom: 10px;
}

.users-view.view-page .left-side .contain-status button {
  margin-bottom: unset;
}

.view-page .left-side .contain-status .buttons button {
  width: 50%;
}

.view-page .left-side .contain-status button:hover {
  background-color: #0067e3;
}

.view-page .left-side .contain-status button:active {
  background-color: #005cc7;
}

.view-page .left-side .contain-status button:last-child {
  margin-bottom: 0;
}

.view-page .right-side {
  width: 62%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  position: fixed;
  right: 40px;
}

.view-page .right-side .table-contain {
  width: 100%;
}

.tasks-view .right-side .table-contain {
  width: 100%;
  /*height: 905px;*/
}

.view-page .right-side .table-contain .table.view {
  height: 100%;
  min-height: unset;
}

.right-side .table-contain .table.view.task {
  height: calc(100vh - 163px);
}

.right-side .table-contain .table.view.user {
  height: calc(100% - 58px);
}

.table.modal{
  min-height: unset !important;
  height: 100%;
}

.view-page .right-side .table-contain .table.view .main-table-contain,
.modal-table .main-table-contain{
  overflow-y: auto;
}

.view-page .right-side .table-contain .table.view .main-table-contain::-webkit-scrollbar,
.modal-table .main-table-contain::-webkit-scrollbar{
  width: 10px;
  background-color: #ffffff;
}

.view-page .right-side .table-contain .table.view .main-table-contain::-webkit-scrollbar-thumb,
.modal-table .main-table-contain::-webkit-scrollbar{
  background-color: #4ecb84;
  border-radius: 10px;
  border: 2px solid #f1f1f6;
}

.view-page .left-side .contain-info .info-block span {
  font-size: 17px;
}

.contain-info .info-block .work-group {
  display: flex;
  flex-wrap: wrap;
}

.view-page .left-side .contain-info .info-block:last-child {
  margin-bottom: 0;
}

.view-page .left-side .contain-info .info-block .creator {
  display: flex;
  justify-content: space-between;
}

.view-page .left-side .contain-info .info-block a {
  color: #0177fd;
}

.tasks-view .left-side .contain-info .info-block span.span {
  font-size: 18px;
}

.view-page .right-side .sub-nav span{
  cursor: pointer;
}

.users-view .left-side .contain-status {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px 40px;
  margin: 0 0 35px;
}

.users-view .left-side .contain-status .bottom {
  display: flex;
  align-items: center;
  padding: 20px 0 0;
}

.users-view .left-side .contain-status .bottom h3 {
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
}

.users-view .left-side .contain-status .buttons {
  display: flex;
  justify-content: space-between;
}

.users-view .left-side .contain-status .buttons button {
  font-size: 18px;
  padding: 5px 0;
  width: 50%;
  border-radius: 5px;
  transition-duration: 200ms;
}

.users-view .left-side .contain-status .buttons button:hover {
  background-color: #0067e3;
}

.users-view .left-side .contain-status .buttons button:active {
  background-color: #005cc7;
}

.users-view .left-side .contain-status .buttons button:first-child {
  margin-right: 8px;
}

.users-view .left-side .contain-status .status {
  display: flex;
  align-items: center;
  width: 100%;
}

.users-view .left-side .contain-status .status div {
  font-size: 26px;
  padding: 8px 0;
  text-align: center;
  width: 100%;
  border-radius: 8px;
}

.users-view .right-side {
  width: 67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: unset;
}

.users-view .right-side .table-contain {
  width: 100%;
  height: 573px;
  margin-bottom: 30px;
  font-size: 0.9em;
}

.right-side .chart {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
}

.chat {
  height: calc(100vh - 163px);
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  padding-bottom: 60px;
}

.chat .input-bar {
  background-color: #ffffff;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.chat .input-bar .inputs {
  display: flex;
  width: 95%;
  height: 100%;
}

.chat .input-bar input {
  font-size: 18px;
  width: 100%;
  padding-left: 20px;
  height: 100%;
}

.chat .input-bar button {
  background-color: #ffffff;
  color: #0177fd;
  transform: rotate(45deg);
  font-size: 30px;
  padding-left: 5px;
}

.chat .messages {
  height: 100%;
  background-color: rgb(218, 243, 229);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 10px 0;
}

.chat .messages::-webkit-scrollbar{
  width: 10px;
  background-color: rgb(218, 243, 229);
}

.chat .messages::-webkit-scrollbar-thumb{
  background-color: #4ecb84;
  border-radius: 10px;
  border: 2px solid rgb(218, 243, 229);
}

.chat .messages .date {
  background-color: rgba(35, 35, 35, 0.5);
  color: #ffffff;
  border-radius: 8px;
  padding: 2px 18px;
  margin-left: 48%;
  width: max-content;
  margin-bottom: 10px;
  margin-top: 10px;
}

.chat .messages .message .text {
  max-width: 50%;
  background-color: #ffffff;
  width: max-content;
  padding: 30px 50px 12px 20px;
  font-size: 18px;
  position: relative;
  margin: 8px 30px;
  border-radius: 20px;
  border-bottom-left-radius: unset;
  min-width: 20%;
  word-break: break-word;
  line-height: 25px;
}

.chat .messages .message .text .owner {
  position: absolute;
  top: 8px;
  left: 18px;
  color: #0177fd;
  font-weight: bold;
  font-size: 16px;
}

.chat .messages .message .text .time {
  font-size: 12px;
  color: #858585;
  position: absolute;
  right: 8px;
  bottom: 5px;
}

.chat .messages .message .text:before {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  left: -30px;
  bottom: 0;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: 70%;
}

.chat .messages .message .text:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: rgb(218, 243, 229);
  left: -30px;
  bottom: 0;
  border-bottom-right-radius: 70%;
}


.chat .messages .message.own {
  justify-content: flex-end;
  display: flex;
}

.chat .messages .message.own.select {
  background-color: rgb(195, 228, 231);
}

.chat .messages .message.own .text {
  background-color: #0177fd;
  color: #ffffff;
  border-radius: 20px;
  border-bottom-right-radius: unset;
}

.chat .messages .message.own .text .time {
  color: #f1f1f6;
}

.chat .messages .message.own .text .owner {
  color: #ffffff;
}

.chat .messages .message.own .text:before {
  background-color: #0177fd;
  right: -30px;
  border-bottom-right-radius: 70%;
  border-bottom-left-radius: unset;
  left: unset;
}

.chat .messages .message.own .text:after {
  right: -30px;
  left: unset;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: 70%;
  background-color: rgb(218, 243, 229);
}

.chat .messages .message.own.select .text:after {
  background-color: rgb(195, 228, 231);
}

input[type="file"] {
  display: none;
}

.file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 60px;
  height: 100%;
  cursor: pointer;
  border-right: 1px solid #0177fd;
  color: #0177fd;
  position: relative;
}

.file-input .button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input .input-types {
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: #ffffff;
  bottom: 60px;
  left: 0;
  font-size: 18px;
  font-weight: bold;
  border-top-right-radius: 12px;
}

.file-input .input-types label {
  cursor: pointer;
  padding: 10px 20px 20px;
}

.file-input .input-types label:first-child {
  padding: 20px 20px 10px;
}

.file-input .input-types label div {
  display: flex;
  align-items: center;
}
.file-input .input-types label div span {
  margin-left: 10px;
}

.previewImage {
  background-color: #ffffff;
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: 121px;
  padding: 10px;
  border-bottom: 1px solid #0177fd;
}

.previewImage div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: max-content;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
}

.previewImage div img {
  border-radius: 12px;
}

.previewImage div span {
  font-size: 25px;
  color: #ffffff;
  font-weight: bold;
  transform: rotate(-45deg);
  position: absolute;
  top: -10px;
  right: -10px;
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #0177fd;
  border-radius: 50%;
}

.previewDoc {
  background-color: #ffffff;
  position: absolute;
  bottom: 60px;
  width: 100%;
  height: 70px;
  padding: 10px 30px;
  border-bottom: 1px solid #0177fd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-bar .previewDoc .document {
  display: flex;
  color: #0177fd;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.previewDoc .document .icon {
  font-size: 36px;
  margin-right: 20px;
}

.previewDoc .clear-button {
  font-size: 36px;
  transform: rotate(-45deg);
  color: #0177fd;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
}

.message .file {
  display: flex;
  background-color: #0177fd;
  border-radius: 10px;
  color: #ffffff;
  /*padding: 12px 20px;*/
  overflow: hidden;
  cursor: pointer;
}

.message.own .file {
  background-color: #ffffff;
  color: #232323;
}

.message .file div {
  padding: 12px 20px;
  display: flex;
  align-items: center;
}

.message .file div .file-name {
  margin: 0 10px;
}

.message .file .icon {
  font-size: 36px;
  color: #ffffff;
}

.message.own .file .icon {
  color: #0177fd;
}

.message .file img {
  max-width: 100%;
}

section.profile-page {
  /*color: rgba(78, 203, 132, 0.3);*/
  background: linear-gradient(to left, #4ECB844D, #4ECB844D, #f1f1f6);
  height: 100vh;
  user-select: none;
}

.profile-page .profile-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 80px)
}

.profile-page .profile-left, .profile-page .profile-right {
  /*height: 100%;*/
  height: 800px;
  width: 450px;
  background-color: #ffffff;
  margin: 0 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.profile-page .profile-left {
  align-items: center;
  padding: 20px;
}

.profile-page .profile-right {
  width: 900px;
}

.profile-page .profile-left .pie-contain, .profile-page .profile-left .avatar-contain, .profile-page .profile-left .statistic-contain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
}

.profile-page .profile-left .avatar-contain {
  height: 30%;
}

.profile-page .profile-left .slider {
  height: 70%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.profile-page .profile-left .slider .slide {
  height: 100%;
  position: absolute;
  width: 100%;
  transition-duration: 200ms;
}

.profile-page .profile-left .slider .slide.one {
  transform: translateX(-110%);
}

.profile-page .profile-left .slider .slide.one.active {
  transform: translateX(0);
}

.profile-page .profile-left .slider .slide.two {
  transform: translateX(110%);
}

.profile-page .profile-left .slider .slide.two.active {
  transform: translateX(0);
}

.profile-page .profile-left .slider .slider-button {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 30px;
  font-size: 20px;
  cursor: pointer;
  transition-duration: 200ms;
}

.profile-page .profile-left .slider .slider-button:hover {
  color: #4ecb84;
}

.profile-page .profile-left .slider .slider-button.left {
  left: 0;
}

.profile-page .profile-left .slider .slider-button.right {
  right: 0;
}

.profile-page .profile-left .statistic-contain {
  height: 30%;
}

.profile-page .profile-left .pie-contain {
  height: 70%;
}

.profile-page .profile-left .avatar-contain .img {
  position: relative;
  margin-bottom: 15px;
}

.profile-page .profile-left .avatar-contain img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.profile-page .profile-left .avatar-contain h3 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
}

.profile-page .profile-left .avatar-contain .icon {
  background-color: #4ecb84;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
}

.profile-page .profile-left .statistic-contain .bar{
  display: grid;
  grid-template-columns: 1fr 2fr 8fr;
  width: 100%;
  align-items: center;
  /*align-items: center;*/
  padding: 7px 10px;
  border-bottom: 1px solid #4ecb84;
}

.profile-page .profile-left .statistic-contain .bar:last-child{
  border-bottom: unset;
}

.profile-page .profile-left .statistic-contain .color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-page .profile-left .statistic-contain .amount {
  font-size: 20px;
  font-weight: bold;
}

.profile-page .profile-right .info-block {
  display: flex;
  flex-direction: column;
}

.profile-page .profile-right .info-block .span {
  padding: 5px 20px;
  border-radius: 4px;
  width: max-content;
}

.profile-page .profile-right .info-block .header {
  margin-bottom: 2px;
  font-size: 18px;
}

.profile-page .profile-right .blocks {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.profile-page .profile-right .selector-info {
  padding: 10px 50px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-page .profile-right .main-info {
  height: 100%;
  display: flex;
  padding: 0 40px;
}

.profile-page .profile-right .main-info .info-block {
  width: 100%;
  margin-bottom: 25px;
}

.profile-page .profile-right .main-info.edit .user-data .info-block {
  margin-bottom: 21px;
}

.profile-page .profile-right .main-info .info-block:last-child {
  margin-bottom: unset;
}

.profile-page .profile-right .main-info .info-block span.header {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.profile-page .profile-right .main-info.edit .user-data .info-block span.header {
  margin-bottom: 7px;
}

.profile-page .profile-right .main-info .info-block span {
  font-size: 18px;
}

.profile-page .profile-right .main-info .info-block input {
  border-bottom: 1px solid #e5e5e5;
  font-size: 18px;
}

.profile-page .profile-right .main-info .user-data,
.profile-page .profile-right .main-info .telegram-data {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.profile-page .profile-right .main-info .telegram-data .info-block {
  margin-bottom: 15px;
}

.profile-page .profile-right h4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 35px;
}

.profile-page .profile-right .btns {
  display: flex;
  justify-content: center;
}

.profile-page .profile-right .btns button {
  width: max-content;
  padding: 8px 20px;
  font-size: 16px;
  border-radius: 4px;
  transition-duration: 200ms;
  margin: 0 10px;
}

.profile-page .profile-right .btns button.bg-blue:hover {
  background-color: #0067e3;
}

.profile-page .profile-right .btns button.bg-blue:active {
  background-color: #005cc7;
}

.profile-page .profile-right .btns button.bg-green:hover {
  background-color: #47b778;
}

.profile-page .profile-right .btns button.bg-green:active {
  background-color: #3d9d67;
}

.profile-page .profile-right .btns button.bg-red:hover {
  background-color: #d24932;
}

.profile-page .profile-right .btns button.bg-red:active {
  background-color: #b23d2a;
}

.profile-page .profile-right .main-info .checkbox-list {
  display: flex;
  flex-direction: column;
}

.profile-page .profile-right .main-info .checkbox-list label {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.profile-page .profile-right .main-info .checkbox-list label .checkbox {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #4ecb84;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 2px;
}

.profile-page .profile-right .main-info .checkbox-list label .checkbox div {
  background-color: #ffffff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.profile-page .profile-right .main-info .checkbox-list label .checkbox.active div {
  background-color: #4ecb84;
}

.profile-page .profile-right .main-info .checkbox-list label span {
  width: calc(100% - 23px);
}

.profile-page .profile-right .main-info .checkbox-list input[type="checkbox"] {
  display: none;
}

.profile-page .profile-right .main-info.edit .checkbox-list label span {
  display: flex;
  align-items: center;
  width: calc(100% - 23px);
  user-select: none;
  margin-left: 23px;
  position: relative;
}

.profile-page .profile-right .main-info.edit .checkbox-list label span:before {
  content: "";
  min-width: 18px;
  min-height: 18px;
  border-radius: 2px;
  border: 1px solid #4ecb84;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 2px;
  left: -23px;
}

.profile-page .profile-right .main-info.edit .checkbox-list label span:after {
  content: "";
  background-color: #ffffff;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: -20px;
}

.profile-page .profile-right .main-info.edit .checkbox-list label input[type="checkbox"]:checked + span:after {
  background-color: #4ecb84;
}

.notification-block .btns-block {
  padding: 5px 20px;
  border-top: 1px solid #f1f1f6;
  position: sticky;
  background-color: #ffffff;
  left: 0;
  bottom: 0;
  display: flex;
}

.notification-block .create-notification {
  background-color: #0177fd;
  color: #ffffff;
  width: max-content;
  font-size: 14px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 200ms;
  border-radius: 8px;
  margin: 0 10px;
}

.notification-block .create-notification:hover {
  background-color: #0067e3;
}

.notification-block .create-notification:active {
  background-color: #005cc7;
}

.notification-block .modal-window .form {
  max-height: 910px;
  height: max-content;
}

.modal-window .selected-contain,
.modal-table .select-contain{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  margin: 10px 0;
  min-height: 42px;
  user-select: none;
}

.modal-window .selected-contain span,
.modal-table .select-contain span{
  font-size: 14px;
  border-radius: 3px;
  padding: 4px 8px;
  width: max-content !important;
  display: inline-block;
  margin: 3px;
  cursor: pointer;
}

.modal-window label .button {
  background-color: #0177fd;
  border-radius: 5px;
  padding: 5px 20px;
  color: #ffffff;
  margin-bottom: 20px;
  cursor: pointer;
}

.notification-block .modal-window label .button:hover {
  background-color: #0067e3;
}

.notification-block .modal-window label .button:active {
  background-color: #005cc7;
}

.modal-window label.checkbox {
  flex-direction: row !important;
  margin-bottom: 20px;
}

.users-page .modal-window label.checkbox {
  margin: 20px 0;
  padding: 0 30px;
}

.modal-window label.checkbox input[type=checkbox] {
  display: none;
}

.modal-window label.checkbox span {
  display: flex;
  align-items: center;
  width: calc(100% - 23px) !important;
  user-select: none;
  margin-left: 23px;
  position: relative;
}

.modal-window label.checkbox span:before {
  content: "";
  min-width: 18px;
  min-height: 18px;
  border-radius: 2px;
  border: 1px solid #4ecb84;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 2px;
  left: -23px;
}

.modal-window label.checkbox span:after {
  content: "";
  background-color: #ffffff;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: -20px;
}

.modal-window label.checkbox input[type="checkbox"]:checked + span:after {
  background-color: #4ecb84;
}

.notification-block .modal-window label .row-inputs {
  display: flex;
  width: 100%;
}

.notification-block .modal-window label .row-inputs input {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.notification-block .modal-window label .row-inputs #unit {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.notification-block .modal-window .hide-block {
  border: 1px solid #e5e5e5;
  padding: 20px;
  border-radius: 8px;
}

.modal-table .contain {
  background-color: #ffffff;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(96, 96, 96, 0.3);
  overflow: hidden;
  border-radius: 15px;
  justify-content: flex-start;
}

.modal-table .table-contain {
  height: calc(100% - 118px);
  width: 100%;
}

.modal-table .table-contain.select-tasks-projects {
  height: calc(100% - 163px);
}

.modal-table .select-part {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 50px 10px;
}

.modal-table .select-contain {
  margin: 0 0 10px !important;
}

.modal-table .btns-contain {
  display: flex;
  justify-content: center;
}

.modal-table .btns-contain button {
  color: #ffffff;
  width: max-content;
  font-size: 14px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 200ms;
  border-radius: 5px;
  margin: 0 5px;
}

.modal-table .btns-contain button.bg-blue:hover {
  background-color: #0067e3;
}

.modal-table .btns-contain button.bg-blue:active {
  background-color: #005cc7;
}

.modal-table .btns-contain button.bg-red:hover {
  background-color: #d24932;
}

.modal-table .btns-contain button.bg-red:active {
  background-color: #b23d2a;
}